import React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import loadable from '@loadable/component'

//styled components
import GlobalStyle from "./styles/GlobalStyle";
import themeHandler from "./helpers/themeHandler";
import { ThemeProvider } from "styled-components";
//provider
import { Provider } from "react-redux";
import store from "./redux/data";
import HomeLoader from "./components/HomeLoader";

const Buscador = loadable(/* webpackPrefetch: true */ () => import("./routes/Buscador"));
const Colecciones = loadable(/* webpackPrefetch: true */ () => import("./routes/Colecciones"));
const SeleccionarDestino = loadable(/* webpackPrefetch: true */ () => import("./routes/SeleccionarDestino"));
const MarcasPremium = loadable(/* webpackPrefetch: true */ () => import("./routes/MarcasPremium"));
const CabelloPro = loadable(/* webpackPrefetch: true */ () => import("./routes/CabelloPro/CabelloPro"));
const HotSale = loadable(/* webpackPrefetch: true */ () => import("./routes/HotSale"));
const RastreaTuCompra = loadable(/* webpackPrefetch: true */ () => import("./routes/RastreaTuCompra"));
const Ubicacion = loadable(/* webpackPrefetch: true */ () => import("./routes/Ubicacion"));
const Politicas = loadable(/* webpackPrefetch: true */ () => import("./routes/Politicas"));
const Terminos = loadable(/* webpackPrefetch: true */ () => import("./routes/Terminos"));
const Contacto = loadable(/* webpackPrefetch: true */ () => import("./routes/Contacto"));
const IncidenteVenta = loadable(/* webpackPrefetch: true */ () => import("./routes/ContactoVenta"));
const Soporte = loadable(/* webpackPrefetch: true */ () => import("./routes/ContactoSoporte"));
const General = loadable(/* webpackPrefetch: true */ () => import("./routes/ContactoGeneral"));
const NovedadesPage = loadable(/* webpackPrefetch: true */ () => import("./routes/NovedadesPage/NovedadesPage"));
const DescuentosMetodosDePagoPage = loadable(/* webpackPrefetch: true */ () => import("./routes/DescuentosMetodosDePagoPage/DescuentosMetodosDePagoPage"));
const RecomendadosPage = loadable(/* webpackPrefetch: true */ () => import("./routes/RecomendadosPage/RecomendadosPage"));
const LandingKerastasePremiere = loadable(/* webpackPrefetch: true */ () => import("./routes/LandingKerastasePremiere/LandingKerastasePremiere"));
const TodasLasNovedadesPorCategoriaPage = loadable(/* webpackPrefetch: true */ () => import("./routes/TodasLasNovedadesPorCategoriaPage/TodasLasNovedadesPorCategoriaPage"));
const LoiPlayPage = loadable(/* webpackPrefetch: true */ () => import("./routes/LoiPlayPage/LoiPlayPage"));
const CategoriasPage = loadable(/* webpackPrefetch: true */ () => import("./routes/CategoriasPage/CategoriasPage"));
const LandingPage = loadable(/* webpackPrefetch: true */ () => import("./routes/LandingPage/LandingPage"));
const LosMasVendidosPage = loadable(/* webpackPrefetch: true */ () => import("./routes/LosMasVendidosPage/LosMasVendidosPage"));
const Tiendas = loadable(/* webpackPrefetch: true */ () => import("./routes/TiendasPage/TiendasPage"));

const currentPath = window.location.pathname;
const friendlyUrl = [
  "/landing/dermocosmetica",
  //"/landing/digital-days",
  //"/landing/oh-belleza",
  "/landing/dia-de-la-madre",
  "/landing/regala-belleza",
];

Sentry.init({
  dsn: "https://dbb48619a232430ce5fc6594af92a403@o144626.ingest.us.sentry.io/4508377049268224",
  environment: process.env.NODE_ENV
});

const container = document.getElementById("app");
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={themeHandler(window.location.hostname)}>
          <GlobalStyle />
          <Routes>
            <Route
              exact
              path="landing/marcas-premium"
              element={<MarcasPremium />}
            />
            <Route exact path="landing/cabellos-pro" element={<CabelloPro />} />
            <Route
              exact
              path="landing/kerastase-premiere"
              element={<LandingKerastasePremiere />}
            />

            <Route exact path="tiendas" element={<Tiendas />} />
            <Route exact path="recomendados" element={<RecomendadosPage />} />
            <Route exact path="novedades" element={<NovedadesPage />} />
            <Route
              exact
              path="todas-las-novedades"
              element={<TodasLasNovedadesPorCategoriaPage />}
            />
            <Route
              exact
              path="los-mas-vendidos"
              element={<LosMasVendidosPage />}
            />
            <Route
              exact
              path="ahorra-pagando-con-transferencia"
              element={<DescuentosMetodosDePagoPage />}
            />
            <Route exact path="categorias" element={<CategoriasPage />} />
            
            <Route exact path="loiplay" element={<LoiPlayPage />} />
            <Route exact path="loiplay/:tabId" element={<LoiPlayPage />} />
            
            <Route exact path="colecciones" element={<Colecciones />} />
            <Route
              exact
              path="seleccionar-destino"
              element={<SeleccionarDestino />}
            />
            <Route exact path="hotsale" element={<HotSale />} />
            <Route exact path="flashsale" element={<HotSale />} />
            <Route exact path="contacto" element={<Contacto />} />
            <Route exact path="ubicacion" element={<Ubicacion />} />
            <Route
              exact
              path="rastrea-tu-compra"
              element={<RastreaTuCompra />}
            />
            <Route
              path="rastrea-tu-compra/:nroOrden"
              element={<RastreaTuCompra />}
            />
            <Route exact path="contacto-venta" element={<IncidenteVenta />} />
            <Route exact path="contacto-soporte" element={<Soporte />} />
            <Route exact path="contacto-general" element={<General />} />
            <Route exact path="politicas" element={<Politicas />} />
            <Route exact path="terminos" element={<Terminos />} />

            {friendlyUrl.includes(currentPath) ? null : (
              <Route
                exact
                path="/landing/:url/:context?"
                element={<LandingPage />}
              />
            )}

            <Route path="buscar/*" element={<Buscador />} />
            <Route path="*" element={<HomeLoader />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

obtenerBotonesDeCompra();
inicializarContadoresHotSale();
